<template>
  <router-view/>
</template>

<script>
export default {
  watch: {
    '$route' (to) {
      document.title = to.meta.title || 'spce.moe';
    }
  },

  mounted() {
    var data = '<!--彡★彡☆ dzn by spce.moe 彡★彡☆彡-->'
    document.body.insertAdjacentHTML('beforeend', data);
  }
}
</script>

<style>
[data-theme='default'] {
  --background-color: #402d47;
  --border-bottom: #503758;
  --border-control: #6f4c7c;
  --text-header: #9b6aad;
}
[data-theme='spring'] {
  --background-color: #64864A;
  --border-bottom: #3d522d;
  --border-control: #546f40;
  --text-header: #b5da9b;
}
:root {
  --background-color: #64864A;
  --border-bottom: #3d522d;
  --border-control: #546f40;
  --text-header: #b5da9b;
}

/* .alert {
  background-color: #423a08;
  color: #e8e8e8;
  justify-content: center;
} */

#app {
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  background-color: #1e1e1e;
}
</style>
