// Defaults
//import { createRouter, createWebHashHistory } from 'vue-router'

// Here we replace WebHashHistory with WebHistory.
// This gets rid of the retarded /#/$path shit.
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: () => import('../views/LandingPage.vue'),
    meta: {
      title: 'spce.moe',
    },
  },
  {
    path: '/shrines/frieren',
    name: 'FrierenView',
    component: () => import('../views/shrines/FrierenView.vue'),
    meta: {
      title: "it's only a mere ten years"
    },
  },
  {
    path: '/home',
    name: 'HomeView',
    component: () => import('../views/HomeView.vue'),
    meta: {
      title: 'home @ spce.moe',
    },
  },
  {
    path: '/about',
    name: 'AboutView',
    component: () => import('../views/AboutView.vue'),
    meta: {
      title: 'about @ spce.moe',
    },
  },
  {
    path: '/blog',
    name: 'BlogView',
    component: () => import('../views/BlogView.vue'),
    meta: {
      title: 'blog @ spce.moe',
    },
  },
  // This route is currently a bit weird.
  // When the route is parsed, the ID doesn't need to match anything- it's just
  // pulled from params. Thus there needs to be either a sanity check in blog (unideal)
  // or a check within this router itself to ensure that the :id key actually
  // exists, otherwise, return 404.
  {
    path: '/blog/:id',
    name: 'blog',
    component: () => import('../components/blog/BlogView.vue'),
    meta: {
      title: 'blog @ spce.moe'
    },
  },
  {
    path: '/public/album',
    name: 'Album',
    component: () => import('../views/AlbumView.vue'),
    meta: {
      title: 'album @ spce.moe',
    },
  },
  {
    path: '/uses',
    name: 'uses',
    component: () => import('../views/UsesView.vue'),
    meta: {
      title: 'uses @ spce.moe',
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue'),
    meta: {
      title: 'contact @ spce.moe',
    },
  },
  {
    path: '/petal',
    name: 'petal',
    component: () => import('../views/PetalView.vue'),
    meta: {
      title: 'petal @ spce.moe',
    },
  },
  // {
  //   path: '/donate',
  //   name: 'donate',
  //   component: () => import('../views/DonateView.vue'),
  //   meta: {
  //     title: 'donate @ spce.moe',
  //   },
  // },
  {
    path: '/:catchAll(.*)*',
    name: '404',
    component: () => import('../views/404NotFound.vue'),
    meta: {
      title: '404 @ spce.moe'
    },
  },
]

const router = createRouter({
  scrollBehavior(to) {
    // Always scroll to top unless otherwise.
    if (to.hash) {
      return {el: to.hash}
    } else {
      return { top: 0 }
    }
  },

  history: createWebHistory(),
  routes
});

export default router
